<script lang="jsx">
import { mapState } from 'vuex'
import { MessageBox, Message } from 'element-ui'
import TojoyFile from '@/components/common/file'
import SmallUserCard from '@/components/democracy/small-user-card.vue'
import TouchHtml from './touch-html.jsx'
import '@/components/democracy/edit-dialog.scss'
import './item.scss'
export default {
  name: 'satisfaction-touch-item',
  components: {
    TojoyFile,
    SmallUserCard,
    TouchHtml,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      approveShow: false,
      approveForm: {
        approveRate: '',
        approveInput: ''
      },
      currentApproveItem: {},
      currentApproveAuditItem: {},
    }
  },
  computed: {
    ...mapState({
      currentUserId: state => state.userInfo.userId
    }),
    rateValues({ currentApproveItem }) {
      const _score = parseFloat(currentApproveItem?.maxScore) || 0
      return {
        tip: _score ? `请评分，最高评${_score}分` : `请评分`,
        value: _score ? _score : 99999,
      }
    }
  },
  methods: {
    /**
     * 联系电话-弹框
     */
    contactMobile(item) {
      MessageBox.alert(item?.customerMobile, `联系电话`, {
          confirmButtonText: '确定',
        });
    },
    handleApproveShow(cr, item) {
      this.currentApproveItem = item
      this.currentApproveAuditItem = cr
      this.approveShow=!this.approveShow
    },
    handleDialogClose() {
      this.approveForm.approveInput = ''
      this.approveForm.approveRate = ''
    },
    handleApproveSubmit() {
      this.$refs['approveForm'].validate((valid) => {
        if (valid) {
          this.$emit('touch-approve', {
            auditUserId: this.data.userid,
            id: this.currentApproveAuditItem?.id,
            score: this.approveForm.approveRate,
            content: this.approveForm.approveInput
          })
          this.approveShow = !this.approveShow
          this.approveForm.approveInput = ''
          this.approveForm.approveRate = ''
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    /**
     * 校验
     */
    validateFn(prop) {
      let validateFn;
      if(prop === 'approveRate') {
        validateFn = (rule, value, callback) => {
          const errorTip = {
            isNull: '请先评分',
            isInvalid: '请输入合法分数',
            isFloat: '最多支持2位小数',
            isOverMax: `最高可评${this.rateValues.value}分`
          }
          if (!value) {
            return callback(new Error(errorTip.isNull));
          }
          setTimeout(() => {
            let _v = parseFloat(value)
            if (value.toString().indexOf('.') === 0 ) {
              callback(new Error(errorTip.isInvalid))
            } else if(!/^[0-9]+[.]?\d{0,2}$/.test(_v)) {
              callback(new Error(errorTip.isFloat))
            } else {
              if (_v > this.rateValues.value) {
                callback(new Error(errorTip.isOverMax))
              } else {
                callback()
              }
            }
          }, 100);
        }
      }
      return validateFn
    },
    /**
     * 审批-弹框
     */
    approveDialog() {
      const attrs = {model: this.approveForm}
      return (
        <el-dialog
          title="审批"
          append-to-body
          center
          destroy-on-close
          close-on-click-modal={false}
          custom-class="appeal-dialog appeal-dialog--satisfaction"
          visible={this.approveShow}
          {...{on:{'update:visible': val => this.approveShow = val}}}
          width="560px"
          on-close={() => this.handleDialogClose()}>
          <el-form
            {...{attrs}}
            label-position="top"
            ref="approveForm">
            <el-form-item
              prop="approveRate"
              label="评分"
              rules={[
                { required: true, message: '请先评分', trigger: 'change'},
                { validator: this.validateFn('approveRate'), trigger: 'blur' }
              ]}>
              <el-input
                ref="approveRate"
                type="number"
                max={100}
                min={0}
                placeholder={this.rateValues.tip}
                v-model={this.approveForm.approveRate}
              >
                <template slot="suffix">
                  <i class="el-input__icon">分</i>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              prop="approveInput">
              <el-input
                v-filteremoji
                ref="approveInput"
                type="textarea"
                resize="none"
                rows={6}
                placeholder="评分意见（选填，最多300字）"
                maxlength="300"
                show-word-limit
                v-model={this.approveForm.approveInput}
              />
            </el-form-item>
          </el-form>
          <template slot="footer">
            <el-button type="primary" size="small" onClick={() => this.handleApproveSubmit()}>提 交</el-button>
          </template>
        </el-dialog>
      )
    },

    scoreShowStatus({state, score}) {
      let showText = ''
      switch (state) {
        case 0:
          showText = `评价中`;
          break;
        case 1:
          showText = `${score}分`;
          break;
        case 2:
          showText = `已过期`;
          break;
      }
      return showText
    },
  },

  render(h) {
    const {data, scoreShowStatus, currentUserId} = this
    return (
      <div class="satisfaction-received-item touch-item">
        <small-user-card
          size={38}
          data={{avatar: data.avatar, userid: data.userid, username: data.username, position: data.position}}
          on-user-card={u => this.$emit('click-user-card', u)}
        >
          {/**<template slot="right">{scoreShowStatus(data)}</template> */}
        </small-user-card>
        { /** 客户感动 TODO加v-show */ }
        <touch-html currentUserId={currentUserId} data={data} isMine={false} isShowApproveBtns={true} />
        { /** 审批 */ this.approveDialog()}
      </div>
    )
  },
}
</script>
<style lang="scss">
.touch-item {
  flex: 1;
  margin-bottom: 15px;
  border-bottom: 1px solid $linecolor;
  .underlings-approve__btns {
    margin-top: 20px;
    .el-button {
      width: 135px;
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
  & > .satisfaction-received-item__textwrap:last-of-type {
    margin-bottom: 20px;
  }
}
</style>
