<template>
  <div class="satisfaction-underlings-view">
    <tojoy-title-content title="下属满意度">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" />
        </div>
      </template>
    </tojoy-title-content>
    <tojoy-pagination-list
      v-if="tabName === 'underlings'"
      key="satisfaction-underlings"
      :loading="loading"
      :total="underlingsData.total"
      :size="pagination.size"
      :data="underlingsData.list"
      @page-change="val => handleChangePage(val, 'underlings')"
    >
      <template #item="{data}">
        <tojoy-card
          :data="{
            time: String(data.evaluateDay).slice(4, 6),
            unit: String(data.evaluateDay).slice(0, 4)
          }"
        >
          <template #rightcard>
            <underlings-item
              v-for="item in data.outputs"
              :key="item.id"
              :data="item"
              @click-user-card="
                u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })
              "
            />
          </template>
        </tojoy-card>
      </template>
    </tojoy-pagination-list>
    <tojoy-pagination-list
      v-if="tabName === 'approve'"
      key="satisfaction-approve"
      schema="block"
      :loading="loading"
      :total="approveData.total"
      :size="pagination.size"
      :data="approveData.list"
      @page-change="val => handleChangePage(val, 'approve')"
    >
      <template #item="{data}">
        <approve-item
          :data="data"
          :key="data.userid"
          :is-approve-text="true"
          :page-type="pageType"
          @submit-audit="handleSubmitAudit"
          @submit-add="handleSubmitAdd"
          @item-remove="handleAuditItemRemove"
          @click-user-card="
            u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })
          "
        />
      </template>
    </tojoy-pagination-list>
    <tojoy-pagination-list
      v-if="tabName === 'complaint'"
      key="satisfaction-complaint"
      :loading="loading"
      :total="complaintData.total"
      :size="pagination.size"
      :data="complaintData.list"
      @page-change="val => handleChangePage(val, 'complaint')"
    >
      <template #item="{data}">
        <tojoy-card
          :data="{
            time: String(data.evaluateDay).slice(4, 6),
            unit: String(data.evaluateDay).slice(0, 4)
          }"
        >
          <template #rightcard>
            <complaint-item
              v-for="item in data.outputs"
              :key="item.id"
              :data="item"
              @complaint-approve="handleComplaintApprove"
              @click-user-card="
                u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })
              "
            />
          </template>
        </tojoy-card>
      </template>
    </tojoy-pagination-list>
    <tojoy-pagination-list
      v-if="tabName === 'touch'"
      key="satisfaction-touch"
      :loading="loading"
      :total="touchData.total"
      :size="pagination.size"
      :data="touchData.list"
      @page-change="val => handleChangePage(val, 'touch')"
    >
      <template #item="{data}">
        <tojoy-card
          :data="{
            time: String(data.evaluateDay).slice(4, 6),
            unit: String(data.evaluateDay).slice(0, 4)
          }"
        >
          <template #rightcard>
            <touch-item
              v-for="item in data.outputs"
              :key="item.id"
              :data="item"
              @touch-approve="handleTouchApprove"
              @click-user-card="
                u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })
              "
            />
          </template>
        </tojoy-card>
      </template>
    </tojoy-pagination-list>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import { mapActions, mapState } from 'vuex'
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyCard from '@/components/business/card'
import TojoyPaginationList from '@/components/business/pagination-list'
import TojoyTabs from '@/components/business/tabs'
import UnderlingsItem from '@/components/satisfaction/underlings-item'
import ApproveItem from '@/components/democracy/approve-item'
import ComplaintItem from '@/components/satisfaction/complaint-item'
import TouchItem from '@/components/satisfaction/touch-item'
import tabsRouteMixins from '@/mixins/tabsRouteMixins'
import watchBroswerBackMixins from '@/mixins/satisfaction/watchBroswerBack'
import { handleDataBF, BF_TYPE } from './handle_data'
import {
  underlingsSatisfaction,
  underlingsApproveList,
  underlingsApproveAudit,
  underlingsApproveDel,
  unserlingsApproveAdd,
  underlingsComplaintList,
  underlingsComplaintAudit,
  underlingsTouchList,
  underlingsTouchAudit
} from '@/api/api_satisfaction'

export default {
  name: 'satisfaction-underlings',
  mixins: [tabsRouteMixins, watchBroswerBackMixins],
  components: {
    TojoyTitleContent,
    TojoyPaginationList,
    TojoyCard,
    TojoyTabs,
    UnderlingsItem,
    ApproveItem,
    ComplaintItem,
    TouchItem
  },
  props: {
    moduleName: {
      type: String,
      default: 'underlings'
    }
  },
  data() {
    return {
      underlingsData: {}, // 客户满意度列表
      approveData: {}, // 待我审核数据
      complaintData: {}, // 投诉处理数据
      touchData: {}, // 感动处理数据
      tabName: this.moduleName,
      pageType: "khmyd",//民主互评
      currentPage: 1, // 标识当前页码，刷新用
      pagination: {
        page: 1,
        size: 10
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      tabs: state => state.satisfaction.teamTabs
    })
  },
  created() {
    this.loading = true
    this.handleTabClick({ name: this.tabName })
  },
  methods: {
    ...mapActions('satisfaction', ['loadTeamRed']),
    /**
     * 加载下属满意度数据
     */
    async loadUnderlingsData(params, options = {}) {
      this.loading = true
      const { code, data } = await underlingsSatisfaction({ ...params }, options)
      if (code === '000000') {
        this.underlingsData = handleDataBF(data, BF_TYPE.teamList)
        this.loading = false
      }
    },
    /**
     * 加载审核数据列表
     */
    async loadApproveData(params, options = {}) {
      this.loading = true
      const { code, data } = await underlingsApproveList({ ...params }, options)
      if (code === '000000') {
        this.approveData = handleDataBF(data, BF_TYPE.approveList)
        this.loading = false
      }
    },
    /**
     * 加载投诉处理列表
     */
    async loadComplaintList(params, options = {}) {
      this.loading = true
      const { code, data } = await underlingsComplaintList({ ...params }, options)
      if (code === '000000') {
        this.complaintData = handleDataBF(data, BF_TYPE.complaintList)
        this.loading = false
      }
    },
    /**
     * 加载感动处理列表
     */
    async loadTouchList(params, options = {}) {
      this.loading = true
      const { code, data } = await underlingsTouchList({ ...params }, options)
      if (code === '000000') {
        this.touchData = handleDataBF(data, BF_TYPE.touchList)
        this.loading = false
      }
    },
    /**
     * 投诉审核提交
     */
    async handleComplaintApprove(params) {
      const { code, data } = await underlingsComplaintAudit(params)
      if (code === '000000') {
        this.$msgSuccess('提交成功')
        this.loadComplaintList({ ...this.pagination, page: this.currentPage })
        this.loadTeamRed()
      }
    },
    /**
     * 感动审核提交
     */
    async handleTouchApprove(params) {
      const { code, data } = await underlingsTouchAudit(params)
      if (code === '000000') {
        this.$msgSuccess('提交成功')
        this.loadTouchList({ ...this.pagination, page: this.currentPage })
        this.loadTeamRed()
      }
    },
    handleTabClick({ name }) {
      this.currentPage = 1
      switch (name) {
        case 'underlings':
          this.loadUnderlingsData({ ...this.pagination })
          break
        case 'approve':
          this.loadApproveData({ ...this.pagination })
          break
        case 'complaint':
          this.loadComplaintList({ ...this.pagination })
          break
        case 'touch':
          this.loadTouchList({ ...this.pagination })
          break
      }
    },
    /**
     * 审核人员-添加
     */
    async handleSubmitAdd({ appealInput, curData }) {
      let _list = []
      for (let i = 0; i < appealInput.length; i++) {
        let aItem = appealInput[i]
        _list.push({
          invitedUserId: aItem.userId,
          invitedUserName: aItem.userName
        })
      }
      // const { code, data } = await unserlingsApproveAdd({
      //   userInputs: _list,
      //   inviteUserId: curData.userid,
      //   version: curData.version
      // })
      // if (code === '000000') {
        for (let d = 0; d < appealInput.length; d++) {
          let dItem = appealInput[d]
          curData.data.push({
            avatar: dItem.avatar,
            organization: dItem.mainOrgChain,
            position: dItem.mainJobName,
            userid: dItem.userId,
            username: dItem.userName
          })
        // }
      }
    },
    /**
     * 提交审核表单
     */
    async handleSubmitAudit({ text, state, curData }) {
      const { code, data } = await underlingsApproveAudit({
        auditRemark: text,
        auditState: state,
        inviteUserId: curData.userid,
        invitedUsers: curData.data.map(u => {
          return {
            invitedUserId: u.userid,
            invitedUserName: u.username
          }
        }),
        version: curData.version,
        total: this.approveData.total
      })
      if (code === '000000') {
        Message.success(`操作成功`)
        // const _findIndex = this.approveData?.list?.findIndex(a => a.userid === curData.userid)
        // this.approveData?.list?.splice(_findIndex, 1)
        this.loadApproveData({ ...this.pagination })
        this.loadTeamRed()
      }
    },
    /**
     * 待我审核-删除某一项
     */
    async handleAuditItemRemove({ item, all }) {
      Message.success(`删除成功`)
      // const { code, data } = await underlingsApproveDel({
      //   invitedUserId: item.userid,
      //   version: all.version
      // })
      // if (code === '000000') {
      //   Message.success(`删除成功`)
      // } else {
      //   Message.error(`删除失败`)
      // }
    },
    handleChangePage(val, type) {
      this.currentPage = val
      console.log('分页：', val)
      switch (type) {
        case 'underlings':
          this.loadUnderlingsData({ page: val, size: this.pagination.size }, { loading: true })
          break
        case 'approve':
          this.loadApproveData({ page: val, size: this.pagination.size }, { loading: true })
          break
        case 'complaint':
          this.loadComplaintList({ page: val, size: this.pagination.size }, { loading: true })
          break
        case 'touch':
          this.loadTouchList({ page: val, size: this.pagination.size }, { loading: true })
          break
      }
    }
  }
}
</script>

<style lang="scss">
.satisfaction-underlings-view {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .tojoy-pagination-list {
    flex: 1;
  }
}
</style>
