<script lang="jsx">
import TojoyRate from '@/components/common/rate'
import TojoyFile from '@/components/common/file'
import SmallUserCard from '@/components/democracy/small-user-card.vue'
import RateHtml from './rate-html.jsx'
import TouchHtml from './touch-html.jsx'
import ComplaintHtml from './complaint-html.jsx'
import '@/components/democracy/edit-dialog.scss'
import './item.scss'
export default {
  name: 'satisfaction-underlings-item',
  components: {
    TojoyRate,
    TojoyFile,
    SmallUserCard,
    RateHtml,
    TouchHtml,
    ComplaintHtml
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  render(h) {
    const {data} = this
    return (
      <div class="satisfaction-received-item underlings-item">
        <small-user-card
          size={38}
          data={{avatar: data.avatar, userid: data.userid, username: data.userName, position: data.job}}
          on-user-card={u => this.$emit('click-user-card', u)}
        >
          <template slot="right">{data.state ? `${data.score ?? 0}分` : '评价中'}</template>
        </small-user-card>
        { /** 内部满意度 TODO加v-show **/ }
        <rate-html data={data} isOuter={true} />
        { /** 外部满意度 TODO加v-show **/  }
        <rate-html data={data} isOuter={false} />
        { /** 客户投诉 TODO加v-show */ }
        <complaint-html data={data} isMine={false} />
        { /** 客户感动 TODO加v-show */}
        <touch-html data={data} isMine={false} />
      </div>
    )
  },
}
</script>
<style lang="scss">
.underlings-item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $linecolor;
  &:last-of-type {
    border-bottom: none;
  }
}
.appeal-dialog {
  .el-dialog {
    &__header {
      border-bottom: 1px solid $linecolor;
    }
    &__footer {
      text-align: center;
      .el-button {
        width: 120px;
      }
    }
    // &__body {
    //   .el-textarea {
    //     &__inner {
    //       border: none;
    //       border-bottom: 1px solid $linecolor;
    //     }
    //   }
    // }
  }
}
</style>
